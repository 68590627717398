<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <PageTitleComponent :title="pageCategoryTitle" />
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="auto">
        <v-btn
          class="mx-0"
          color="primary"
          :to="`/service/terms_of_${termCategory}/add`"
        >
          <v-icon>add</v-icon>
          <span class="pl-2">{{ pageCategoryTitle }} 등록</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-simple-table
          class="tbl elevation-2"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="item in headers"
                  :key="item.text"
                  :class="`text-${item.align}`"
                >
                  {{ item.text }}
                </th>
              </tr>
            </thead>
            <TbodyPreRender
              :is-loading="isLoading"
              :cols="headers.length"
              :items-length="items.length"
            />
            <tbody v-if="!isLoading">
              <tr
                v-for="item in items"
                :key="`service-tos-tr-${item.idx}`"
              >
                <td>
                  <router-link :to="`/service/terms_of_${termCategory}/item/${item.idx}`">
                    {{ item.title }}
                  </router-link>
                </td>
                <td class="text-center">
                  {{ item.is_activated == 1 ? 'Y' : 'N' }}
                </td>
                <td class="text-center">
                  {{ item.updated_at | dayjsLocalDatetime }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <PaginationComponent v-model="pagination" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import paginationLib from '../_elements/pagination/paginationLib'
import PaginationComponent from '../_elements/pagination/Pagination'
import PageTitleComponent from '../_elements/title/PageTitle'
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import Api from './api/userApi'

export default {
  components: {
    PageTitleComponent,
    PaginationComponent,
    TbodyPreRender
  },
  data() {
    return {
      isLoading: false,
      termCategory: '',
      pageCategoryTitle: '',
      query: '',
      items: [],
      pagination: {},
      headers: [
        { text: '제목', align: 'left' },
        { text: '디스플레이 여부', align: 'center' },
        { text: '최근 수정일', align: 'center' }
      ]
    }
  },
  created() {
    const categoryInfo = {
      title: '',
      category: ''
    }
    this.pageCategoryTitle = categoryInfo.title
    this.termCategory = categoryInfo.category
    const params = this.$route.query
    if (params.page === undefined) {
      this.$router.push({ query: { page: 1 } }, () => {})
    } else {
      this.query = params.query || ''
      this.pagination.page = parseInt(params.page, 10) || 1
      this.getList()
    }
  },
  methods: {
    async getList() {
      this.isLoading = true
      const res = await Api.getList(
        this.termCategory,
        this.pagination.page, this.pagination.limit,
        this.query
      )
      if (res.data.result === true) {
        this.items = res.data.data.item_list
        this.pagination = paginationLib.paginationMapper(res.data.data.pagination)
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  ::v-deep table {
    min-width: 500px;
  }
}
.theme--light.v-data-table {
  ::v-deep table > thead > tr > th {
    background-color: #EEEEEE;
  }
}
.v-skeleton-loader {
  ::v-deep .v-skeleton-loader__list-item {
    padding: 0px !important;
  }
}
</style>
