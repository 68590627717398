import { AuthApi } from '@/libraries/api'

async function changeMyPassword(currentPassword, newPassword) {
  const url = '/account/me/password'
  const dt = {
    current_password: currentPassword,
    new_password: newPassword
  }
  const res = await AuthApi().put(url, dt)
  const { result } = res.data
  return result
}

// function signInByToken() {
//   const url = '/account/sign_in/by_token'
//   const dt = {
//     device_type: 'web'
//   }
//   // setUserAccessToken()
//   return AuthApi().post(url, dt)
// }

export default {
  changeMyPassword
}
